import React, { useRef, useEffect } from 'react';

function Modal({
	children,
	buttonMsg,
	closeModal,
	hideButton,
	size = 'md',
	background = 'bg-white',
	header = null,
	keepOpenOnOutsideClick,
	customButton,
	modalShouldConfirm,
	cancelButtonMsg,
	callback,
}) {
	const modalRef = useRef(null);
	const handleClick = (event) => {
		if (modalRef.current.contains(event.target) || keepOpenOnOutsideClick) {
			return;
		}
		closeModal();
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClick, false);
		return () => document.removeEventListener('mousedown', handleClick, false);
	});

	const handleConfirm = (confirm) => {
		if (confirm) {
			callback();
			closeModal();
			return true;
		}
		closeModal();
		return false;
	};
	return (
		<div
			className="modal d-block"
			style={{ background: 'rgba(0,0,0,.6)', overflowY: 'auto' }}
		>
			<div
				className={`modal-dialog modal-dialog modal-dialog modal-${size} mx-auto`}
				style={size === 'xl' ? { maxWidth: '1140px' } : null}
			>
				<div
					className={`modal-content border-0 ${background}`}
					ref={modalRef}
				>
					<div className="modal-header pb-0">
						{header}
						<button
							type="button"
							className="close"
							aria-label="Close"
							onClick={closeModal}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{children}
						<div>
							{customButton && customButton}
							{!customButton && !hideButton && (
								<div className="d-flex flex-column align-items-center my-5">
									<button
										className="btn btn-primary"
										type="button"
										aria-label="Ok"
										onClick={
											modalShouldConfirm
												? () => handleConfirm(true)
												: closeModal
										}
									>
										{buttonMsg || 'Ok'}
									</button>
									{modalShouldConfirm && (
										<button
											className="btn btn-link inline-link mt-3"
											type="button"
											onClick={() => handleConfirm(false)}
										>
											{cancelButtonMsg || 'Cancel'}
										</button>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Modal;
