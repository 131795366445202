// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import chunk from 'lodash/chunk';

import Layout from '../components/Layout';
import ErrorBoundary from '../components/ErrorBoundary';
import CarrierPoliciesBox from '../components/CarrierPoliciesBox';
import { CARRIER_POLICIES, PORTAL_URL } from '../models/SimpleModels';
import IF from '../components/IF';
import Modal from '../components/Modal';

function IndexPage() {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleCloseITCModal = () => {
		if (typeof window !== 'undefined') {
			window.localStorage.setItem('gatewayModal', 'true');
		}
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const hasSeenModal = window.localStorage.getItem('gatewayModal');
			if (!hasSeenModal) {
				setIsModalOpen(true);
			}
		}
	}, []);

	const chunks = chunk(CARRIER_POLICIES, 2);
	console.log('chunks', chunks);
	// This will throw if the GATSBY_ENV is not set, which is a good thing. We want to know if it's not set and thus not loading a URL.
	const portalUrl = PORTAL_URL[process.env.GATSBY_ENV.toUpperCase()];

	return (
		<Layout>
			<IF condition={isModalOpen}>
				<Modal
					size="xl"
					closeModal={handleCloseITCModal}
					buttonMsg="Continue to Site"
					background="bg-gradient-mobelux"
					customButton={(
						<button
							type="button"
							className="buddy-btn-mobelux d-block mx-auto my-5"
							onClick={handleCloseITCModal}
						>
							Go to Site
						</button>
					)}
				>
					<div className="w-md-75 px-lg-5 mx-auto">
						<img src="/buddy-logo-wordmark.png" className="w-100" alt="logo" style={{ maxWidth: '10rem' }} />
						<p className="mt-5">
							Hello!
						</p>
						<p>
							Looking for more information on Buddy's Insurance Gateway? Click
							{' '}
							<a
								className="mobelux-link"
								href="https://buddy.insure"
							>
								here
							</a>
							{' '}
							to read more.
						</p>
					</div>
				</Modal>
			</IF>
			<ErrorBoundary childName="CustomerPortal">
				<section className="container" id="insured-portal">
					<h1 className="text-center">Insured Portal</h1>
					<div className="copy">
						<p className="w-100 pl-2">
							Find your policies by selecting a carrier below.
						</p>
						<div>
							{chunks.map((row) => (
								<div key={`${row[0].title}-${row[1]?.title}`} className="d-flex flex-column align-items-center align-items-md-stretch justify-content-center flex-md-row w-100">
									{row.map((carrier) => (
										<CarrierPoliciesBox
											key={carrier.title}
											title={carrier.title}
											policies={carrier.policies}
											href={`https://${carrier.subdomain}.${portalUrl}`}
										/>
									))}
								</div>
							))}
						</div>
					</div>
				</section>
			</ErrorBoundary>
		</Layout>
	);
}

export default IndexPage;
