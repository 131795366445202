import React from 'react';

const CarrierPoliciesBox = ({ title, policies, href }) => (
	<a
		href={href}
		className="d-flex flex-column border p-3 rounded m-2 w-100 color-black"
		style={{ maxWidth: '400px' }}
		aria-label={`${title} Insured Portal`}
	>
		<h4 className="text-center">{title}</h4>
		<ul className="red-dots">
			{policies.map((policyName) => (
				<li key={policyName}>{policyName}</li>
			))}
		</ul>
	</a>
);

export default CarrierPoliciesBox;
